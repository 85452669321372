import { useWeb3ModalProvider } from '@web3modal/ethers/vue';
import type { CallAbiContract } from '~/types/Web3Provider';
import useAbiAccess from './useAbiAccess';
import { BrowserProvider } from 'ethers';
import { useEnvs } from '#imports';

export default () => {
  const { getMethodObj } = useAbiAccess();
  const { walletProvider } = useWeb3ModalProvider();
  const { blockchain } = useEnvs();

  const sendContractMethod = async (sendParams: CallAbiContract, onConfirmation?: (confirmations?: number) => void) => {
    const transaction = await getMethodObj(sendParams);

    if (walletProvider.value && transaction) {
      const provider = new BrowserProvider(walletProvider.value);
      const signer = await provider.getSigner();
      const currentGasPrice = (await provider.getFeeData()).gasPrice;
      const sending = await signer.sendTransaction({ ...transaction, gasPrice: currentGasPrice });

      if (onConfirmation) {
        provider.on('block', async () => {
          const confirmations = await sending.confirmations();
          if (confirmations <= blockchain.minConfirmationsCount) onConfirmation(confirmations);
          if (Number(confirmations) >= blockchain.minConfirmationsCount) {
            provider.off('block');
            return;
          }
        });
      }

      return sending;
    }
  };

  return { sendContractMethod };
};
